<template>
  <div>
    <template v-for="(cart, i) in carts">
      <div v-bind:key="i" v-bind:class="i != carts.length - 1 ? `mb-8` : ``">
        <div class="d-flex">
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <span class="text-dark mb-1 font-size-lg">
              {{ cart.product.product.productName }}
            </span>
            <span class="text-muted font-size-sm">
              {{ getProductVariant(cart.product) }}
            </span>
          </div>
          <span
            v-if="cart.discount > 0"
            class="text-dark mb-1 font-size-sm text-decoration-line-through"
          >
            {{ setRupiah(cart.price) }}
          </span>
          <span class="font-weight-bold text-dark mb-1 font-size-lg ml-2">
            {{ setRupiah(cart.price - cart.discount) }}
          </span>
        </div>
        <div class="d-flex align-items-center mt-3">
          <div class="d-flex flex-column flex-grow-1">
            <div v-if="cart.product.product.isMembership == false">
              <b-button
                class="btn-icon mr-2"
                variant="light-success"
                size="xs"
                @click="decrease(cart)"
                :disabled="cart.product.product.isMembership != false"
              >
                <i class="ki ki-minus icon-sm"></i>
              </b-button>
              <span class="font-weight-bolder">
                {{ numberFormat(cart.quantity) }}
              </span>
              <b-button
                class="btn-icon ml-2"
                variant="light-success"
                size="xs"
                @click="increase(cart)"
                :disabled="cart.product.product.isMembership != false"
              >
                <i class="ki ki-plus icon-sm"></i>
              </b-button>
            </div>
          </div>
          <b-button
            class="btn-icon"
            variant="light-danger"
            size="xs"
            @click="remove(i)"
          >
            <i class="flaticon-delete icon-sm"></i>
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { SET_CART } from "@/core/services/store/pos.module.js";
import { setRupiah, numberFormat } from "@/core/utils";

export default {
  props: {
    carts: Array,
  },
  data: () => ({}),
  methods: {
    setRupiah,
    numberFormat,
    getProductVariant(product) {
      let labels = [];

      if (product.variants == null || product.variants.length == 0) {
        return "";
      } else {
        product.variants.forEach((variant) => {
          variant.details.forEach((detail) => {
            if (detail.selectionDefault) labels.push(detail.label);
          });
        });

        return labels.join(", ");
      }
    },
    decrease(cart) {
      const self = this;
      if (cart.product.product.isMembership != false) return;
      if (cart.quantity > 1) {
        cart.quantity -= 1;
        self.calculatePrice(cart);
      }
    },
    increase(cart) {
      const self = this;
      if (cart.product.product.isMembership != false) return;
      if (cart.product.manageStock == true) {
        if (cart.product.stock == 0) {
          self.$message.warning({
            zIndex: 10000,
            message: "Yah, stok habis",
          });
          return;
        } else if (cart.product.stock == cart.quantity) {
          self.$message.warning({
            zIndex: 10000,
            message: `Yah, produk ini hanya punya stok sebanyak ${cart.product.stock}`,
          });
          return;
        }
      }

      cart.quantity += 1;
      self.calculatePrice(cart);
    },
    remove(index) {
      const self = this;
      self.carts.splice(index, 1);
      self.$store.dispatch(SET_CART, self.carts);
    },
    calculatePrice(cart) {
      cart.price = cart.product.regularPrice;
      if (cart.product.variants != null) {
        cart.product.variants.forEach((variant) => {
          variant.details.forEach((detail) => {
            if (detail.selectionDefault) cart.price += detail.priceLevel;
          });
        });
      }

      cart.price *= cart.quantity;
    },
  },
};
</script>
