<template>
  <b-card-footer>
    <b-button
      v-for="(item, i) in table.rows"
      v-bind:key="i"
      block
      :variant="item.id == paymentMethod?.id ? `success` : `outline-secondary`"
      class="mr-2 mb-2"
      @click="changePaymentMethod(item)"
    >
      <span v-if="item.image != null" class="svg-icon svg-icon-lg">
        <img :src="mediaUrl(item.image?.label)" class="h-20px" />
      </span>
      {{ item.label }}
    </b-button>
  </b-card-footer>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_PAYMENT_METHOD } from "@/core/services/store/pos.module.js";
import { mediaUrl } from "@/core/utils";

export default {
  data: () => ({
    serverParams: {
      pageNumber: 1,
      pageSize: 0,
      actived: true,
      orderBy: "sequenceNumber, label",
    },
    table: {
      isLoading: false,
      columns: [],
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  computed: {
    ...mapGetters(["carts", "paymentMethod", "store"]),
  },
  watch: {
    store() {
      const self = this;
      self.getPaymentMethods();
    },
  },
  created() {
    const self = this;
    self.getPaymentMethods();
  },
  methods: {
    mediaUrl,
    getPaymentMethods() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/store/${self.store.id}/payment-method`,
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    changePaymentMethod(paymentMethod) {
      const self = this;
      if (self.carts.length == 0) {
        self.$message.warning({
          zIndex: 10000,
          message:
            "Yah, belum bisa pilih cara pembayaran karena pesanan masih kosong",
        });
      } else {
        self.$store.dispatch(SET_PAYMENT_METHOD, paymentMethod);
      }
    },
  },
};
</script>
