<template>
  <div class="card card-custom mb-8">
    <div class="card-body rounded p-0 d-flex bg-light">
      <div
        class="d-flex flex-column flex-lg-row-auto w-auto w-lg-350px w-xl-550px w-xxl-800px p-5 p-md-5"
      >
        <h2 class="font-weight-bolder text-dark mb-4">Cari atau Scan Produk</h2>
        <b-tabs content-class="p-0">
          <b-tab title="Scan Barcode">
            <div
              class="d-flex flex-center py-2 px-6 border border-top-0 bg-white rounded-bottom"
            >
              <span class="svg-icon svg-icon-lg svg-icon-primary">
                <inline-svg src="media/svg/icons/Devices/Camera.svg" />
              </span>
              <b-input
                v-model="barcode"
                type="text"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Tarok kursor disini & coba scan"
                autofocus
                @input="onScan"
              />
            </div>
          </b-tab>
          <b-tab title="Pencarian" active>
            <div
              class="d-flex flex-center py-2 px-6 border border-top-0 bg-white rounded-bottom"
            >
              <span class="svg-icon svg-icon-lg svg-icon-primary">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
              <b-input
                v-model="keyword"
                type="text"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Ketik nama produk disini"
                @input="onSearch"
              />
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div
        class="d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover"
        style="background-image: url('media/svg/illustrations/copy.svg')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    barcode: null,
    keyword: null,
  }),
  methods: {
    onScan() {
      const self = this;
      self.$emit("onScan", self.barcode);
    },
    onSearch() {
      const self = this;
      self.$emit("onSearch", self.keyword);
    },
  },
};
</script>
