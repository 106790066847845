<template>
  <fragment>
    <b-card-footer>
      <b-button
        block
        variant="primary"
        size="lg"
        :disabled="!readyToPayment"
        @click="openModal"
      >
        Lanjut Ke Pembayaran
      </b-button>
    </b-card-footer>

    <b-modal
      v-model="modal.show"
      title="Pembayaran"
      :no-close-on-backdrop="true"
      :ok-only="true"
      :ok-disabled="paymentMethod?.group?.id != 3 && modal.amount < grandTotal"
      ok-title="Selesaikan Pesanan"
      @ok="handleSubmitPenjualan"
    >
      <div class="d-flex align-items-center mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Dilayani Oleh :
          </span>
        </div>
        <span class="text-dark-75 font-size-lg font-weight-bold">
          {{ user.fullName }}
        </span>
      </div>
      <div class="d-flex align-items-center mb-4">
        <div class="d-flex flex-column flex-grow-1">
          <span class="text-dark-75 font-size-lg font-weight-bold">
            Pelanggan :
          </span>
        </div>
        <b-button
          v-b-tooltip.hover
          title="Klik untuk memilih pelanggan lainnya"
          variant="outline-success"
          size="sm"
          class="dropdown-toggle"
          @click="openModalCustomer"
        >
          {{ member?.id == null ? `Umum` : member.fullName }}
        </b-button>
      </div>
      <template v-if="[1, 2].includes(paymentMethod?.group?.id)">
        <div class="d-flex align-items-center mb-4">
          <div class="d-flex flex-column flex-grow-1">
            <span class="text-dark-75 font-size-lg font-weight-bold">
              Jumlah Yang Dibayar :
            </span>
          </div>
          <span class="text-dark-75 font-size-lg font-weight-bold">
            {{ setRupiah(modal.amount) }}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column flex-grow-1">
            <span class="text-dark-75 font-size-lg font-weight-bold">
              Jumlah Yang Dibayar :
            </span>
          </div>
        </div>
        <b-button
          v-for="(fraction, i) in suggestions"
          v-bind:key="i"
          variant="outline-success"
          size="sm"
          class="mr-2 mt-2"
          @click="setUang(fraction)"
        >
          {{ fraction == 0 ? `Uang Pas` : setRupiah(fraction) }}
        </b-button>
        <vue-number
          v-model="modal.amount"
          v-bind="money"
          class="form-control mt-2 mb-4"
        />
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column flex-grow-1">
            <span class="text-dark-75 font-size-lg font-weight-bold">
              Uang Kembalian :
            </span>
          </div>
          <span class="text-dark-75 font-size-lg font-weight-bold">
            {{ setRupiah(changeDue) }}
          </span>
        </div>
        <div
          v-if="paymentMethod?.group?.id == 3"
          class="d-flex align-items-center mt-4 mb-4"
        >
          <div class="d-flex flex-column flex-grow-1">
            <span class="text-dark-75 font-size-lg font-weight-bold">
              Jumlah Sisa Bayar :
            </span>
          </div>
          <span class="text-dark-75 font-size-lg font-weight-bold">
            {{ setRupiah(remainingPayment) }}
          </span>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="modalCustomer.show"
      title="Cari Pelanggan"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Pilih Pelanggan"
      @ok="handleChooseCustomer"
    >
      <treeselect
        ref="CUSTOMER"
        v-model="modalCustomer.customerId"
        :async="true"
        :loadOptions="getAllCustomer"
        placeholder="Ketik id, nama pelanggan disini"
        class="mb-4"
        @input="getCustomerById"
      />

      <b-button variant="secondary" block @click="openModalPelanggan">
        Datanya belum ada ? Klik untuk buat baru pelanggan
      </b-button>

      <template v-if="modalCustomer.customer?.id != null">
        <div class="separator separator-dashed border-1"></div>
        <Member :form="modalCustomer.customer" />
        <b-button
          block
          variant="outline-danger"
          class="mt-8"
          @click="handleResetCustomer"
        >
          Ganti pelanggan lain
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="modalTagihan.show"
      title="Bagikan Tagihan"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :ok-only="true"
      ok-title="Tutup"
      @ok="handleCloseTagihan"
    >
      <lottie-vue-player
        :src="`/media/animation/lottie-success.json`"
        :autoplay="true"
        :loop="true"
        style="width: 100%; height: 150px"
      />
      <div class="mb-4 text-center">
        <span class="font-size-h4 font-weight-bolder">
          {{ modalTagihan.message }}
        </span>
      </div>

      <b-button
        block
        size="lg"
        variant="outline-secondary"
        @click="handlePrint"
      >
        <div class="d-flex flex-column">
          <div class="m-2">
            <i class="flaticon2-printer" />
          </div>
          Cetak Tagihan
        </div>
      </b-button>

      <div class="d-flex justify-content-between mt-4">
        <b-button
          block
          size="lg"
          :variant="
            modalTagihan.method == `email` ? `success` : `outline-secondary`
          "
          class="m-0 mr-2"
          @click="handleChooseShareMethod('email')"
        >
          <div class="d-flex flex-column">
            <div class="m-2">
              <i class="flaticon-multimedia" />
            </div>
            Bagikan via Email
          </div>
        </b-button>

        <b-button
          block
          size="lg"
          :variant="
            modalTagihan.method == `whatsapp` ? `success` : `outline-secondary`
          "
          class="m-0 ml-2"
          @click="handleChooseShareMethod('whatsapp')"
        >
          <div class="d-flex flex-column">
            <div class="m-2">
              <i class="flaticon-whatsapp" />
            </div>
            Bagikan via Whatsapp
          </div>
        </b-button>
      </div>

      <b-input-group
        v-if="[`email`, `whatsapp`].includes(modalTagihan.method)"
        class="mt-2 mb-8"
      >
        <template #append>
          <b-button @click="handleShare" variant="success"> Bagikan </b-button>
        </template>

        <b-form-input
          v-if="modalTagihan.method == `email`"
          v-model="modalTagihan.shareTo"
          type="email"
          autocomplete="off"
          placeholder="Masukkan email pelanggan disini..."
        />
        <b-form-input
          v-if="modalTagihan.method == `whatsapp`"
          v-model="modalTagihan.shareTo"
          type="tel"
          autocomplete="off"
          placeholder="Masukkan nomor whatsapp pelanggan disini..."
        />
      </b-input-group>
    </b-modal>

    <b-modal
      v-model="modalPelanggan.show"
      title="Tambah Data Pelanggan"
      :no-close-on-backdrop="true"
      :ok-only="true"
      ok-title="Simpan dan Pilih Pelanggan"
      @ok="handleSubmitRegistrasi"
    >
      <b-form-group label="Tipe Identitas">
        <b-form-radio-group
          v-model="modalPelanggan.identityType"
          :options="options.identityType"
          buttons
          button-variant="outline-success"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group label="Nomor Identitas">
        <b-form-input v-model="modalPelanggan.nomorIdentitas"></b-form-input>
      </b-form-group>
      <b-form-group label="Nama Lengkap">
        <b-form-input v-model="modalPelanggan.fullName"></b-form-input>
      </b-form-group>
      <b-form-group label="Jenis Kelamin">
        <b-form-radio-group
          v-model="modalPelanggan.gender"
          :options="options.gender"
          buttons
          button-variant="outline-success"
        ></b-form-radio-group>
      </b-form-group>
    </b-modal>
  </fragment>
</template>

<script>
import { apiUrl } from "@/core/services/api.url";
import Member from "@/view/content/common/Member.vue";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";
import { SET_MEMBER, SET_CART } from "@/core/services/store/pos.module.js";
import { setRupiah, isNullOrEmpty, suggestions } from "@/core/utils";

export default {
  components: {
    Member,
  },
  data: () => ({
    businessId:
      process.env.NODE_ENV === "production"
        ? apiUrl.business.prodId
        : apiUrl.business.devId,
    basePrintURL:
      process.env.NODE_ENV === "production"
        ? apiUrl.print.prod
        : apiUrl.print.dev,
    money: {
      decimal: ",",
      separator: ".",
      prefix: "Rp",
      suffix: "",
      precision: 0,
      nullValue: "",
      masked: false,
      reverseFill: false,
    },
    modal: {
      show: false,
      amount: 0,
    },
    modalCustomer: {
      show: false,
      customerId: null,
      customer: null,
    },
    modalTagihan: {
      show: false,
      message: null,
      transactionId: null,
      method: null,
      shareTo: null,
      proceed: 0,
    },
    modalPelanggan: {
      show: false,
      identityType: 0,
      nomorIdentitas: null,
      fullName: null,
      gender: 0,
    },
    options: {
      identityType: [
        { text: "KTP", value: 0 },
        { text: "SIM", value: 1 },
        { text: "Smart SIM", value: 2 },
      ],
      gender: [
        { text: "Pria", value: 0 },
        { text: "Wanita", value: 1 },
      ],
      minimizable: false,
      playerSize: "standard",
      backgroundColor: "#fff",
      backgroundStyle: "color",
      theme: {
        controlsView: "standard",
        active: "light",
        light: {
          color: "#3D4852",
          backgroundColor: "#fff",
          opacity: "0.7",
        },
        dark: {
          color: "#fff",
          backgroundColor: "#202020",
          opacity: "0.7",
        },
      },
    },
    suggestions,
    invoice: {},
  }),
  computed: {
    ...mapGetters([
      "store",
      "carts",
      "paymentMethod",
      "discount",
      "grandTotal",
      "member",
    ]),
    ...mapGetters("auth", ["user"]),
    readyToPayment() {
      const self = this;
      return self.carts.length > 0 && self.paymentMethod?.id != null;
    },
    changeDue() {
      const self = this;
      let calculate = self.modal.amount - self.grandTotal;
      return calculate >= 0 ? calculate : 0;
    },
    remainingPayment() {
      const self = this;
      let calculate = self.grandTotal - self.modal.amount;
      return calculate >= 0 ? calculate : 0;
    },
  },
  methods: {
    setRupiah,
    openModal() {
      const self = this;

      self.$store.dispatch(SET_MEMBER, null);
      if ([1, 2].includes(self.paymentMethod?.group?.id)) {
        self.modal.amount = self.grandTotal;
      } else if (self.paymentMethod?.group?.id == 3) {
        self.modal.amount = 0;
      } else {
        self.modal.amount = 0;
      }
      self.modal.show = true;
    },
    openModalCustomer() {
      const self = this;
      self.getDefaultCustomer();
      self.modalCustomer.customerId = null;
      self.modalCustomer.customer = self.member;
      self.modalCustomer.show = true;
    },
    openModalPelanggan() {
      const self = this;

      if (self.store.clubMemberSystem) {
        const routeData = self.$router.resolve({
          name: "member",
        });
        window.open(routeData.href, "_blank");
      } else {
        self.modalPelanggan.identityType = 0;
        self.modalPelanggan.nomorIdentitas = null;
        self.modalPelanggan.fullName = null;
        self.modalPelanggan.gender = 0;
        self.modalPelanggan.show = true;
      }
    },
    setUang(fraction) {
      const self = this;
      self.modal.amount = fraction == 0 ? self.grandTotal : fraction;
    },
    getDefaultCustomer() {
      const self = this;

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member`,
            params: {
              pageNumber: 1,
              pageSize: 20,
              actived: true,
            },
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.$refs.CUSTOMER.defaultOptions = response.data.data.map(
                (x) => ({
                  id: x.id,
                  label: x.fullName,
                })
              );
              self.$refs.CUSTOMER.initialize();
            }
          });
      }
    },
    getAllCustomer({ action, searchQuery, callback }) {
      const self = this;
      if (action === ASYNC_SEARCH) {
        if (self.store != null) {
          self.$store
            .dispatch("apis/get", {
              url: `/store/${self.store.id}/member`,
              params: {
                fullName: searchQuery,
                actived: true,
                orderBy: "fullName",
              },
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                callback(
                  null,
                  response.data.data.map((x) => ({
                    id: x.id,
                    label: x.fullName,
                  }))
                );
              }
            });
        }
      }
    },
    getCustomerById() {
      const self = this;

      if (!isNullOrEmpty(self.modalCustomer.customerId) && self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/member/${self.modalCustomer.customerId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.modalCustomer.customer = {
                id: response.data.id,
                image: response.data.image,
                fullName: response.data.fullName,
                membershipNumber: response.data.membershipNumber,
                email: response.data.email,
                phone: response.data.phone,
                memberCard: response.data.memberCard,
              };
            }
          });
      }
    },
    getTransactionById(transactionId) {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.store.id}/${transactionId}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });

            self.$router.push({ name: self.route.table });
          } else {
            self.invoice = {
              transactionNumber: response.data.transactionNumber,
              transactionDate: response.data.created?.createdAt,
              customer: response.data.customer,
              subTotal: response.data.subTotal,
              taxPercentage: response.data.taxPercentage,
              tax: response.data.tax,
              discount: response.data.discount,
              grandTotal: response.data.grandTotal,
              amount: response.data.amount,
              paymentAmount: response.data.paymentAmount,
              paymentChange: response.data.paymentChange,
              paymentRemaining: response.data.paymentRemaining,
              outstanding: response.data.outstanding,
              paymentMethod: response.data.paymentMethod,
              carts: response.data.carts,
              created: response.data.created,
            };
          }
        });
    },
    handleSubmitPenjualan(event) {
      event.preventDefault();

      const self = this;
      let customerMandatory = false;
      self.carts.forEach((cart) => {
        if (
          cart.product.isMembership == true &&
          cart.product.dependOnMember == true
        )
          customerMandatory = true;
      });

      if (customerMandatory && self.member?.id == null) {
        self.$message.warning({
          zIndex: 10000,
          message: `Dalam pesanan ini ada produk yang mewajibkan data pelanggan, mohon dipilih dulu pelanggannya ya`,
        });
      } else {
        self.$dialog
          .confirm("Kamu yakin mau menyelesaikan pesanan ini ?", {
            okText: "Ya, Selesaikan",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            let payload = {
              memberId: self.member?.id,
              paymentMethodId: self.paymentMethod.id,
              paymentAmount: self.modal.amount,
              discountId: null,
              discount: self.discount,
              carts: self.carts.map((x) => ({
                productId: x.product.id,
                variants:
                  x.product.variants == null
                    ? null
                    : x.product.variants.map((y) => ({
                        parentId: y.id,
                        detailId: y.details.find(
                          (d) => d.selectionDefault == true
                        )?.id,
                      })),
                quantity: x.quantity,
                discount: x.discount,
                coachId: x.coachId,
              })),
            };

            self.$store
              .dispatch("apis/post", {
                url: `/transaction/${self.store.id}/pos`,
                params: payload,
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.modal.amount = 0;
                  self.modal.show = false;

                  self.modalTagihan.transactionId = response.data.id;
                  self.modalTagihan.message = response.message;
                  self.modalTagihan.method = null;
                  self.modalTagihan.proceed = 0;
                  self.modalTagihan.show = true;

                  self.$store.dispatch(SET_MEMBER, null);
                  self.getTransactionById(self.modalTagihan.transactionId);
                }
              })
              .finally(() => {
                dialog.close();
              });
          });
      }
    },
    handleSubmitRegistrasi(event) {
      event.preventDefault();
      const self = this;

      if (self.store != null) {
        self.$dialog
          .confirm("Kamu yakin mau menyimpan data pelanggan ini ?", {
            okText: "Ya, Simpan",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/post", {
                url: `/store/${self.store.id}/member`,
                params: {
                  identityType: self.modalPelanggan.identityType,
                  nomorIdentitas: self.modalPelanggan.nomorIdentitas,
                  fullName: self.modalPelanggan.fullName,
                  gender: self.modalPelanggan.gender,
                },
              })
              .then((response) => {
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$store.dispatch(SET_MEMBER, {
                    id: response.data.id,
                    fullName: response.data.fullName,
                    membershipNumber: response.data.membershipNumber,
                    email: response.data.email,
                    phone: response.data.phone,
                  });

                  self.modalPelanggan.identityType = 0;
                  self.modalPelanggan.nomorIdentitas = null;
                  self.modalPelanggan.fullName = null;
                  self.modalPelanggan.gender = 0;
                  self.modalPelanggan.show = false;
                  self.modalCustomer.show = false;
                }
              })
              .finally(() => dialog.close());
          });
      }
    },
    handleChooseCustomer() {
      const self = this;
      if (self.modalCustomer.customer?.id != null) {
        self.$store.dispatch(SET_MEMBER, self.modalCustomer.customer);
      }
    },
    handleResetCustomer() {
      const self = this;
      self.modalCustomer.customerId = null;
      self.modalCustomer.customer = null;
      self.$store.dispatch(SET_MEMBER, []);
    },
    handleChooseShareMethod(method) {
      const self = this;

      self.modalTagihan.method = method;
      if (method == "email") self.modalTagihan.shareTo = self.member?.email;
      else if (method == "whatsapp")
        self.modalTagihan.shareTo = self.member?.phone;
    },
    resetModalTagihan() {
      const self = this;
      self.modalTagihan.message = null;
      self.modalTagihan.transactionId = null;
      self.modalTagihan.method = null;
      self.modalTagihan.shareTo = null;
      self.modalTagihan.proceed = 0;
      self.modalTagihan.show = false;
      self.$store.dispatch(SET_CART, []);
    },
    handleCloseTagihan(event) {
      event.preventDefault();

      const self = this;
      if (self.modalTagihan.proceed == 0) {
        self.$dialog
          .confirm(
            `Kamu yakin mau menutup dialog ini tanpa membagikan pesanannya terlebih dahulu ?`,
            {
              okText: "Ya, Tutup",
              cancelText: "Ga jadi",
              loader: true,
            }
          )
          .then((dialog) => {
            self.resetModalTagihan();
            dialog.close();
          });
      } else {
        self.resetModalTagihan();
      }
    },
    handlePrint() {
      const self = this;
      self.modalTagihan.proceed += 1;

      window.open(
        `${self.basePrintURL}/nota/${self.businessId}/${self.store.id}/${self.modalTagihan.transactionId}`,
        "_blank"
      );
    },
    handleShare() {
      const self = this;

      if (isNullOrEmpty(self.modalTagihan.shareTo)) {
        self.$message.warning({
          zIndex: 10000,
          message: `Maaf, ${self.modalTagihan.method} diisi dulu ya`,
        });

        return;
      }

      let payload = {
        transactionId: self.modalTagihan.transactionId,
        method: self.modalTagihan.method,
        shareTo: self.modalTagihan.shareTo,
      };

      self.$dialog
        .confirm(
          `Kamu yakin mau membagikan pesanan ini melalui ${self.modalTagihan.method} ?`,
          {
            okText: "Ya, Bagikan",
            cancelText: "Ga jadi",
            loader: true,
          }
        )
        .then((dialog) => {
          self.$store
            .dispatch("apis/post", {
              url: `/transaction/${self.store.id}/pos/share`,
              params: payload,
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                self.modalTagihan.proceed += 1;
                self.$message.success({
                  zIndex: 10000,
                  message: response.message,
                });
              }
            })
            .finally(() => dialog.close());
        });
    },
  },
};
</script>
