<template>
  <b-row>
    <b-col lg="10" class="mb-6">
      <b-button
        v-for="(item, index) in categories"
        v-bind:key="index"
        :variant="item.selected ? `success` : `outline-secondary`"
        class="mr-2 mb-2"
        @click="changeCategory(item)"
      >
        {{ item.label }}
      </b-button>
    </b-col>
    <b-col lg="2" class="text-right">
      <b-button-group>
        <b-button
          @click="changeMode(viewModeEnum.TABLE)"
          :variant="
            viewMode == viewModeEnum.TABLE ? `secondary` : `outline-secondary`
          "
          class="btn-icon"
        >
          <i class="ki ki-menu icon-sm"></i>
        </b-button>
        <b-button
          @click="changeMode(viewModeEnum.GRID)"
          :variant="
            viewMode == viewModeEnum.GRID ? `secondary` : `outline-secondary`
          "
          class="btn-icon"
        >
          <i class="ki ki-menu-grid icon-sm"></i>
        </b-button>
      </b-button-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_VIEW_MODE } from "@/core/services/store/personalize.module.js";
import { viewModeEnum } from "@/core/datasource/enum";

export default {
  props: {
    categories: Array,
  },
  data: () => ({
    viewModeEnum,
  }),
  computed: {
    ...mapGetters(["viewMode"]),
  },
  created() {
    const self = this;
    if (self.viewMode == null) self.changeMode(self.viewModeEnum.TABLE);
  },
  methods: {
    changeCategory(params) {
      const self = this;

      self.categories.forEach((item) => {
        item.selected = false;
      });

      params.selected = true;
      self.$emit("onCategoryChange", params.id);
    },
    changeMode(params) {
      const self = this;
      self.$store.dispatch(SET_VIEW_MODE, params);
    },
  },
};
</script>
