<template>
  <b-card no-body class="card-custom">
    <b-card-body>
      <div class="d-flex align-items-center mb-7">
        <div class="d-flex flex-column flex-grow-1">
          <h3 class="font-weight-bolder text-dark">Pesanan Saat Ini</h3>
        </div>
        <b-button
          v-if="carts.length > 0"
          v-b-tooltip.hover
          title="Hapus semua"
          class="btn-icon"
          variant="light-danger"
          size="xs"
          @click="deleteAll"
        >
          <i class="flaticon-delete icon-sm"></i>
        </b-button>
      </div>
      <EmptyCart v-if="carts.length == 0" />
      <CartList v-else-if="carts.length > 0" :carts="carts" />
    </b-card-body>
    <PaymentSummary v-if="store?.id != null" />
    <PaymentMethod v-if="store?.id != null" />
    <Payment v-if="store?.id != null" />

    <!-- <div id="printMe" v-show="false">
      <p>Habibullah</p>
    </div>
    <b-button v-print="'#printMe'">Print</b-button> -->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_CART } from "@/core/services/store/pos.module.js";
import EmptyCart from "./EmptyCart";
import CartList from "./CartList";
import PaymentSummary from "./PaymentSummary";
import PaymentMethod from "./PaymentMethod";
import Payment from "./Payment";

export default {
  components: {
    EmptyCart,
    CartList,
    PaymentSummary,
    PaymentMethod,
    Payment,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["store", "carts"]),
  },
  methods: {
    deleteAll() {
      const self = this;

      if (self.carts.length > 0) {
        self.$dialog
          .confirm("Kamu yakin mau menghapus semua pesanan ini ?", {
            okText: "Ya, Hapus Aja",
            cancelText: "Ga jadi",
            loader: true,
          })
          .then((dialog) => {
            self.$store.dispatch(SET_CART, []);
            dialog.close();
          });
      }
    },
  },
};
</script>
