<template>
  <b-card-footer>
    <!-- <b-input-group class="mb-8">
      <template #append>
        <b-button>Pakai</b-button>
      </template>
      <b-form-input
        autocomplete="off"
        placeholder="Punya kode voucher ?"
        class="border-hover-success"
      ></b-form-input>
    </b-input-group> -->
    <div class="d-flex align-items-center">
      <div class="d-flex flex-column flex-grow-1">
        <span class="text-dark-75 font-size-lg font-weight-bold">
          Sub Total
        </span>
      </div>
      <span class="text-dark-75 font-size-lg font-weight-bold">
        {{ setRupiah(subTotal) }}
      </span>
    </div>
    <div v-if="store?.taxPercentage > 0" class="d-flex align-items-center">
      <div class="d-flex flex-column flex-grow-1">
        <span class="text-dark-75 font-size-lg font-weight-bold">
          Pajak ({{ setPersentase(store?.taxPercentage) }})
        </span>
      </div>
      <span class="text-dark-75 font-size-lg font-weight-bold">
        {{ setRupiah(tax) }}
      </span>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex flex-column flex-grow-1">
        <span class="text-dark-75 font-size-lg font-weight-bold">Diskon</span>
      </div>
      <span class="text-dark-75 font-size-lg font-weight-bold">
        {{ setRupiah(discount) }}
      </span>
    </div>
    <div class="d-flex align-items-center pt-2">
      <div class="d-flex flex-column flex-grow-1">
        <span class="text-dark-75 font-size-lg font-weight-bolder">
          Total Pesanan
        </span>
      </div>
      <span
        class="font-weight-bolder font-size-h3 label label-xl label-light-primary label-inline px-3 py-5 min-w-45px"
      >
        {{ setRupiah(grandTotal) }}
      </span>
    </div>
  </b-card-footer>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SET_TAX,
  SET_DISCOUNT,
  SET_GRAND_TOTAL,
} from "@/core/services/store/pos.module.js";
import { setRupiah, setPersentase } from "@/core/utils";

export default {
  data: () => ({
    // discount: 0,
  }),
  computed: {
    ...mapGetters(["store", "carts"]),
    subTotal() {
      const self = this;
      return self.carts.reduce((accumulator, cart) => {
        return parseInt(accumulator) + parseInt(cart.price);
      }, 0);
    },
    subTotalTax() {
      const self = this;

      if (self.store.taxPercentage > 0) {
        return self.carts
          .filter((x) => x.product.vat)
          .reduce((accumulator, cart) => {
            return (
              parseInt(accumulator) +
              parseInt(cart.price) -
              parseInt(cart.discount)
            );
          }, 0);
      } else {
        return 0;
      }
    },
    discount() {
      const self = this;
      let discount = self.carts.reduce((accumulator, cart) => {
        return parseInt(accumulator) + parseInt(cart.discount);
      }, 0);

      self.$store.dispatch(SET_DISCOUNT, discount);

      return discount;
    },
    tax() {
      const self = this;
      if (self.store != null) {
        let calculate = (self.subTotalTax * self.store.taxPercentage) / 100;
        self.$store.dispatch(SET_TAX, calculate);

        return calculate;
      } else {
        return 0;
      }
    },
    grandTotal() {
      const self = this;
      let calculate = self.subTotal + self.tax - self.discount;
      self.$store.dispatch(SET_GRAND_TOTAL, calculate);

      return calculate;
    },
  },
  methods: {
    setRupiah,
    setPersentase,
    /* getDiscount() {
      const self = this;
      self.$store.dispatch(SET_DISCOUNT, 0);
    }, */
  },
};
</script>
