export const mediaGroupEnum = {
  BUSINESS_LOGO: 0,
  USER_ACCOUNT: 1,
  STORE_LOGO: 2,
  PRODUCT: 3,
  PRODUCT_VARIANT: 4,
  PAYMENT_METHOD: 5,
  MEMBER: 6,
  IDENTITY_CARD: 7,
};

export const formModeEnum = {
  ADD: 0,
  EDIT: 1,
};

export const viewModeEnum = {
  TABLE: 0,
  GRID: 1,
};
