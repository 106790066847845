<template>
  <fragment>
    <b-row>
      <b-col v-if="table.rows.length == 0" lg="12">
        <div
          class="d-flex align-items-center justify-content-center text-center p-20"
        >
          <div>
            <div class="mb-3 mt-n5">
              <span class="svg-icon svg-icon-10x svg-icon-danger">
                <inline-svg src="media/svg/icons/General/Sad.svg" />
              </span>
            </div>
            <h5>Belum ada produk yang siap diperjual belikan</h5>
          </div>
        </div>
      </b-col>
      <template v-else>
        <fragment>
          <fragment v-if="viewMode == viewModeEnum.GRID">
            <b-col
              lg="3"
              v-for="(item, index) in table.rows"
              v-bind:key="index"
            >
              <b-button
                block
                class="card card-custom gutter-b card-stretch p-0"
                @click="openModal(item)"
              >
                <div
                  class="card-body d-flex flex-column rounded bg-light justify-content-between"
                >
                  <div class="text-center rounded mb-7">
                    <img
                      :src="`${mediaUrl(item.product?.image?.label)}`"
                      class="mw-100 w-50px"
                    />
                  </div>
                  <div>
                    <h4
                      class="font-size-h5 font-weight-bolder"
                      v-bind:class="
                        isOutOfStock(item.availability)
                          ? `text-muted`
                          : `text-dark-75`
                      "
                    >
                      {{ item.product?.productName }}
                    </h4>
                    <span
                      class="font-size-h6 font-weight-bolder mb-4"
                      v-bind:class="
                        isOutOfStock(item.availability)
                          ? `text-muted`
                          : `text-dark-75`
                      "
                    >
                      {{
                        isOutOfStock(item.availability)
                          ? `Stock Habis`
                          : setRupiah(item.regularPrice)
                      }}
                    </span>
                  </div>
                </div>
              </b-button>
            </b-col>
          </fragment>
          <b-col v-else lg="12">
            <b-table
              ref="TABLE_PRODUCT"
              class="table-head-custom table-vertical-center table-head-bg table-borderless"
              :fields="columns"
              :items="table.rows"
              selectable
              hover
              select-mode="single"
              @row-selected="openModal"
            >
              <template #head(product.productName)="data">
                <span class="text-dark-75">{{ data.label }}</span>
              </template>
              <template #head(regularPrice)="data">
                <span class="text-dark-75">{{ data.label }}</span>
              </template>
              <template #cell(regularPrice)="data">
                {{ setRupiah(data.value) }}
              </template>
            </b-table>
          </b-col>
          <b-col lg="12">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column flex-grow-1">
                Menampilkan {{ showing }} dari {{ table.totalRecords }}
              </div>
              <b-pagination
                size="md"
                v-model="serverParams.pageNumber"
                @change="onPageChange"
                :per-page="serverParams.pageSize"
                :total-rows="table.totalRecords"
                align="right"
              >
                <template #first-text>
                  <i class="flaticon2-fast-back icon-sm"></i>
                </template>
                <template #prev-text>
                  <i class="flaticon2-back icon-sm"></i>
                </template>
                <template #next-text>
                  <i class="flaticon2-next icon-sm"></i>
                </template>
                <template #last-text>
                  <i class="flaticon2-fast-next icon-sm"></i>
                </template>
              </b-pagination>
            </div>
          </b-col>
        </fragment>
      </template>
    </b-row>

    <b-modal
      v-model="modal.show"
      :title="modal.product?.product?.productName"
      :no-close-on-backdrop="true"
      size="md"
      @close="onClearSelected"
    >
      <div class="d-flex justify-content-between flex-column h-100">
        <div class="h-100 mb-4">
          <div class="d-flex flex-column">
            <div
              v-if="viewMode == viewModeEnum.GRID"
              class="bgi-no-repeat bgi-size-cover rounded min-h-100px w-100 mb-4"
              :style="`background-image: url(${mediaUrl(
                modal.product?.product?.image?.label
              )})`"
            />

            <span class="font-weight-bolder text-dark-75 font-size-h2 m-0 pb-1">
              {{ setRupiah(modal.price) }}
            </span>
          </div>
        </div>
        <InputMoney
          label="Ada diskon ?"
          v-model="modal.discount"
          :useHorizontal="false"
        />
        <div class="d-flex flex-column">
          <b-form-group
            v-if="!modal.product?.product?.isMembership"
            label="Kuantiti :"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group :aria-describedby="ariaDescribedby">
              <b-input-group-prepend>
                <b-button variant="outline-success" @click="decrease">
                  <i class="ki ki-minus"></i>
                </b-button>
              </b-input-group-prepend>

              <b-form-input
                v-model="modal.quantity"
                type="number"
                min="1"
                class="border-success text-center font-weight-bolder"
                @input="calculatePrice"
              />

              <b-input-group-append>
                <b-button variant="outline-success" @click="increase">
                  <i class="ki ki-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-for="(variant, indexVariant) in modal.product?.variants"
            v-bind:key="indexVariant"
            :label="`${variant.label} :`"
            :class="
              modal.product?.variants?.length - 1 == indexVariant
                ? `m-0 mb-2`
                : ``
            "
          >
            <b-button
              v-for="(detail, indexDetail) in variant.details"
              v-bind:key="indexDetail"
              :variant="detail.selectionDefault ? `success` : `outline-success`"
              size="sm"
              class="mr-2 mb-2"
              @click="changeVariant(indexVariant, indexDetail)"
            >
              {{ detail.label }}
            </b-button>
          </b-form-group>
        </div>
        <b-form-group
          v-if="modal.product?.product?.dependOnCoach"
          label="Pilih Coach / Personal Trainer"
        >
          <treeselect
            ref="COACH"
            v-model="modal.coachId"
            :async="true"
            :loadOptions="getAllCoach"
            placeholder="Ketik nama coach disini"
            class="mb-4"
          />
        </b-form-group>
      </div>
      <template #modal-footer>
        <b-button
          variant="primary"
          block
          class="font-weight-bolder font-size-sm"
          @click="addToCart"
        >
          Tambahkan Pesanan
        </b-button>
      </template>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_CART } from "@/core/services/store/pos.module.js";
import { mediaUrl, setRupiah } from "@/core/utils";
import { viewModeEnum } from "@/core/datasource/enum";
import { posSystem as columns } from "@/core/datasource/column";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";

export default {
  props: {
    serverParams: Object,
    table: Object,
  },
  data: () => ({
    columns,
    viewModeEnum,
    modal: {
      show: false,
      product: {},
      quantity: 1,
      discount: 0,
      price: 0,
      coachId: null,
    },
  }),
  computed: {
    ...mapGetters(["store", "viewMode", "carts"]),
    showing() {
      const self = this;
      let page = self.serverParams.pageNumber - 1;
      return self.serverParams.pageSize * page + self.table.rows.length;
    },
  },
  methods: {
    mediaUrl,
    setRupiah,
    getAllCoach({ action, searchQuery, callback }) {
      const self = this;
      if (action === ASYNC_SEARCH) {
        if (self.store != null) {
          self.$store
            .dispatch("apis/get", {
              url: `/store/${self.store.id}/coach`,
              params: {
                keyword: searchQuery,
                actived: true,
              },
            })
            .then((response) => {
              if (response.error) {
                self.$message.error({
                  zIndex: 10000,
                  message: response.message,
                });
              } else {
                callback(
                  null,
                  response.data.data.map((x) => ({
                    id: x.id,
                    label: x.fullName,
                  }))
                );
              }
            });
        }
      }
    },
    getById(id) {
      const self = this;

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/product/${id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.modal.product = response.data;
              self.modal.price = response.data.regularPrice;
              self.modal.discount = 0;
              self.modal.quantity = 1;
              self.modal.show = true;
            }
          });
      }
    },
    isOutOfStock(stock) {
      return stock != null && stock == 0;
    },
    onClearSelected() {
      const self = this;
      if (self.viewMode == viewModeEnum.TABLE) {
        self.$refs.TABLE_PRODUCT.clearSelected();
      }
    },
    onPageChange(params) {
      this.$emit("onPageChange", params);
    },
    openModal(params) {
      const self = this;

      if (self.viewMode == viewModeEnum.GRID) {
        if (params != null) {
          if (self.isOutOfStock(params.stock) == false) {
            self.modal.product = params;
            self.modal.price = params.regularPrice;
            self.modal.discount = 0;
            self.modal.quantity = 1;
            self.modal.show = true;
          }
        }
      } else {
        params = params[0];

        if (params != null) {
          if (self.isOutOfStock(params.stock) == false) {
            self.modal.product = params;
            self.modal.price = params.regularPrice;
            self.modal.discount = 0;
            self.modal.quantity = 1;
            self.modal.show = true;
          }
        }
      }
    },
    decrease() {
      const self = this;
      if (self.modal.product.membership != null) return;
      if (self.modal.quantity > 1) {
        self.modal.quantity -= 1;
        self.calculatePrice();
      }
    },
    increase() {
      const self = this;
      if (self.modal.product.membership != null) return;
      if (self.modal.product.stock != null) {
        if (self.modal.product.stock == 0) {
          self.$message.warning({
            zIndex: 10000,
            message: "Yah, stok habis",
          });
          return;
        } else if (self.modal.product.stock == self.modal.quantity) {
          self.$message.warning({
            zIndex: 10000,
            message: `Yah, produk ini hanya punya stok sebanyak ${self.modal.product.stock}`,
          });
          return;
        }
      }

      self.modal.quantity += 1;
      self.calculatePrice();
    },
    changeVariant(indexVariant, indexDetail) {
      const self = this;

      self.modal.product.variants[indexVariant].details.forEach((item) => {
        item.selectionDefault = false;
      });
      self.modal.product.variants[indexVariant].details[
        indexDetail
      ].selectionDefault = true;
      self.calculatePrice();
    },
    calculatePrice() {
      const self = this;

      self.modal.price = self.modal.product.regularPrice;
      if (self.modal.product.variants != null) {
        self.modal.product.variants.forEach((variant) => {
          variant.details.forEach((detail) => {
            if (detail.selectionDefault) self.modal.price += detail.priceLevel;
          });
        });
      }

      self.modal.price *= self.modal.quantity;
      self.modal.price -= self.modal.discount;
    },
    addToCart() {
      const self = this;

      let closeable = true;
      if (
        self.modal.product.variants != null ||
        self.modal.product.variants?.length > 0
      ) {
        self.modal.product.variants.forEach((variant) => {
          let next = false;
          variant.details.forEach((detail) => {
            if (detail.selectionDefault) next = true;
          });
          if (!next) {
            closeable = false;
            self.$message.warning({
              zIndex: 10000,
              message: `Maaf, variant ${variant.label} dipilih dulu ya`,
            });
            return;
          }
        });
      }

      if (closeable) {
        let findProduct = self.carts.findIndex(
          (x) =>
            x.product.id == self.modal.product.id &&
            self.getProductVariant(x.product) ==
              self.getProductVariant(self.modal.product)
        );

        if (findProduct >= 0) {
          self.carts[findProduct].coachId = self.modal.coachId;
          self.carts[findProduct].quantity += self.modal.quantity;
          if (
            self.modal.product.stock != null &&
            self.carts[findProduct].quantity > self.modal.product.stock
          )
            self.carts[findProduct].quantity = self.modal.product.stock;
        } else {
          self.carts.push({
            product: self.modal.product,
            quantity: self.modal.quantity,
            discount: self.modal.discount,
            price: self.modal.price,
            coachId: self.modal.coachId,
          });
        }

        self.$store.dispatch(SET_CART, self.carts);

        self.modal.show = false;
        self.onClearSelected();
      }
    },
    getProductVariant(product) {
      let labels = [];

      product.variants.forEach((variant) => {
        variant.details.forEach((detail) => {
          if (detail.selectionDefault) labels.push(detail.label);
        });
      });

      return labels.join(", ");
    },
  },
};
</script>
