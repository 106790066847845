<template>
  <div>
    <b-row>
      <b-col lg="8">
        <b-card no-body class="card-custom">
          <b-card-body>
            <SearchProduct @onScan="onScan" @onSearch="onSearch" />
            <ProductCategory
              v-if="categories.length > 0"
              :categories="categories"
              @onCategoryChange="onCategoryChange"
            />
            <ProductList
              :serverParams="serverParams"
              :table="table"
              @onPageChange="onPageChange"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="4">
        <Cart />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchProduct from "./product/SearchProduct";
import ProductCategory from "./product/ProductCategory";
import ProductList from "./product/ProductList";
import Cart from "./cart/Cart";

export default {
  components: {
    SearchProduct,
    ProductCategory,
    ProductList,
    Cart,
  },
  data: () => ({
    categories: [],
    serverParams: {
      pageNumber: 1,
      pageSize: 12,
      productName: null,
      barcode: null,
      category: null,
      variant: true,
      actived: true,
      orderBy: null,
    },
    table: {
      isLoading: false,
      columns: [],
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  computed: {
    ...mapGetters(["store"]),
  },
  watch: {
    store() {
      const self = this;
      self.getCategory();
      self.getProducts();
    },
  },
  created() {
    const self = this;
    self.getCategory();
    self.getProducts();
  },
  methods: {
    onScan(params) {
      const self = this;
      self.serverParams.barcode = params;
      self.getProducts();
    },
    onSearch(params) {
      const self = this;
      self.serverParams.productName = params;
      self.getProducts();
    },
    onCategoryChange(params) {
      const self = this;
      self.serverParams.pageNumber = 1;
      self.serverParams.category = params;
      self.getProducts();
    },
    updateParams(newProps) {
      const self = this;
      self.serverParams = Object.assign({}, self.serverParams, newProps);
    },
    onPageChange(params) {
      const self = this;
      self.updateParams({ pageNumber: params });
      self.getProducts();
    },
    getCategory() {
      const self = this;

      self.categories = [
        {
          id: null,
          label: "Semua Produk",
          selected: true,
        },
      ];

      if (self.store != null) {
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/product/category`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.categories.push(
                ...response.data.map((x) => {
                  return {
                    id: x,
                    label: x,
                    selected: false,
                  };
                })
              );
            }
          });
      }
    },
    getProducts() {
      const self = this;

      if (self.store != null) {
        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: `/store/${self.store.id}/product`,
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      }
    },
  },
};
</script>
